import * as React from 'react';
import Link from 'next/link';
import { PageWrapper, Content } from 'components/layout';
import { Heading, Box, Paragraph, Text } from 'components/design-system';

const statusCodes: { [code: number]: string } = {
  400: 'Bad Request',
  404: 'Halaman ini tidak dapat ditemukan',
  405: 'Method Not Allowed',
  500: 'Maaf, terjadi kesalahan pada server.',
};

export interface ErrorProps {
  statusCode: keyof typeof statusCodes;
  title?: string;
}

const ErrorPage: React.FC<ErrorProps> = ({ title, statusCode }) => {
  return (
    <PageWrapper title="Error | KawalCOVID19">
      <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box textAlign="center">
          <Text variant={1400} color="accents02" mb="md" style={{ userSelect: 'none' }}>
            {statusCode}
          </Text>
          <Heading variant={900} as="h1" mb="md">
            {title || statusCodes[statusCode]}
          </Heading>
          <Paragraph variant={500}>
            Silakan kembali ke{' '}
            <Link href="/">
              <a>halaman utama</a>
            </Link>
            .
          </Paragraph>
        </Box>
      </Content>
    </PageWrapper>
  );
};

ErrorPage.displayName = 'ErrorPage';

ErrorPage.defaultProps = {
  statusCode: 404,
};

export default ErrorPage;
