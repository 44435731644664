import * as React from 'react';
import ErrorPage from './_error';

const NotFoundPage: React.FC = () => {
  return <ErrorPage statusCode={404} />;
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
